<template>
  <div class="commonHead">
    <div class="title-logo">晟工数智数字人管理后台</div>
    <div class="user">
      <img src="../images/back/avator_header.png" alt="头像" />
      <el-dropdown>
        <span class="el-dropdown-link">
          {{ userInfo.username
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="modify.show = true"
            >修改密码</el-dropdown-item
          >
          <el-dropdown-item @click.native="LogOut()">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog
      class="commonDialog"
      title="修改密码"
      :visible.sync="modify.show"
      width="500px"
      @close="modifyDown"
    >
      <el-form
        :model="modify.ruleForm"
        :rules="modify.rule"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="旧密码" prop="oncePassword">
          <el-input
            class="waicengPhone"
            v-model="modify.ruleForm.oncePassword"
            placeholder="请输入旧密码"
            clearable
            show-password
            style="width: 100% !important"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input
            class="waicengPhone"
            v-model="modify.ruleForm.password"
            placeholder="请输入新密码"
            show-password
            clearable
            style="width: 100% !important"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="onPassword">
          <el-input
            class="waicengPhone"
            v-model="modify.ruleForm.onPassword"
            placeholder="请输入确认密码"
            show-password
            clearable
            style="width: 100% !important"
          ></el-input>
        </el-form-item>
        <div class="flex" style="margin-top: 50px; justify-content: center">
          <el-button type="primary" plain @click="modify.show = false"
            >取消</el-button
          >
          <el-button type="primary" @click="resetForm">保存</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modify: {
        show: false,
        ruleForm: {},
        rule: {
          oncePassword: [
            {
              required: true,
              trigger: "blur",
              message: "请输入旧密码",
            },
          ],
          password: [
            {
              required: true,
              trigger: "blur",
              message: "请输入新密码",
            },
          ],
          onPassword: [
            {
              required: true,
              trigger: "blur",
              message: "请输入新密码",
            },
          ],
        },
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    //重置密码
    resetForm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let obj = this.modify.ruleForm;
          if (obj.password !== obj.onPassword) {
            this.$message("新密码两次密码不一致,请重新确认密码");
            obj.onPassword = null;
            return;
          }
          let params = {
            user: this.userInfo.phone,
            password: obj.password,
            oncePassword: obj.oncePassword,
          };
          this.http
            .post("/login/resetPassByOldPassword", params)
            .then((res) => {
              if (res.data.success) {
                this.$message.success("修改成功！");
                this.modifyDown();
              } else {
                this.$message.error(res.data.message);
              }
            })
            .catch((err) => {});
        }
      });
    },
    //关闭修改密码弹窗
    modifyDown() {
      this.$refs.ruleForm.resetFields();
      this.modify.show = false;
    },
    //退出登录
    LogOut() {
      this.removeCacheData("userInfo");
      this.removeCacheData("Authorization");
      this.$router.replace("/");
    },
  },
  components: {},
  computed: {
    userInfo() {
      return JSON.parse(this.getCacheData("userInfo"));
    },
  },
};
</script>

<style lang="less" scoped>
.el-dropdown-link {
  cursor: pointer;
  font-size: 16px;
  color: white;
}
.commonHead {
  .user {
    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
  .title-logo {
    font-size: 36px;
    font-weight: bold;
  }
  color: white;
  background-color: #121276;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
