<template>
  <div class="home">
    <!-- 头部 -->
    <commonHead></commonHead>
    <el-container>
      <el-aside>
        <!-- 侧边导航 -->
        <navigation></navigation>
      </el-aside>
      <el-main
        ><!-- 内容区 -->
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import commonHead from "../components/commonHead.vue";
import navigation from "../components/navigation.vue";
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  components: { commonHead, navigation },
};
</script>

<style lang="less" scoped>
.el-aside {
  width: auto !important;
}
.content {
  width: 100%;
  display: flex;
}
.el-main {
  height: 100vh;
  padding: 20px 40px 120px;
}
.home {
  height: 100vh;
  width: 100vw;
  background: #040e44;
  overflow: hidden;
}
</style>
